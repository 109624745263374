<template>
    <div class="card card-f-h flex flex-col">
        <ConfirmationModal :codeConfirmation="true" @success="getAll" />

        <ModalAddCategory
            :visible="modalAddCategoryVisible"
            @refresh="getAll"
            @close="modalAddCategoryVisible = false"
        />

        <ModalEditCategory
            :visible="modalEditCategoryVisible"
            :selectedCategoryId="selectedCategoryId"
            @refresh="getAll"
            @close="modalEditCategoryVisible = false"
        />

        <div class="flex items-end justify-between border-b border-gray-300 mb-3 pb-3">
            <el-button type="primary" :disabled="!userCan('create service categories')" @click="addCategory">
                {{ $t('services.add') }}
            </el-button>
            <el-input v-model="quickSearch" suffix-icon="el-icon-search" class="w-72" :placeholder="$t('services.search')" clearable />
        </div>

        <CategoriesTable
            :tableData="categoriesData"
            @handleEdit="handleEdit"
            @handleDelete="handleDelete"
        />

        <InitialLoader v-if="$wait.is('loading.initial_*')" />

        <NoDataInformation :data="noDataInformation" waitKey="loading.initial_categories" />
    </div>
</template>
<script>
import Api from './categories.api';

export default {
    components: {
        ModalAddCategory:  () => import(/* webpackChunkName: "Modals/ModalAddCategory" */ './modals/ModalAddCategory'),
        ModalEditCategory: () => import(/* webpackChunkName: "Modals/ModalEditCategory" */ './modals/ModalEditCategory'),
        CategoriesTable:   () => import(/* webpackChunkName: "Categories/CategoriesTable" */ './components/CategoriesTable'),
    },

    data() {
        return {
            noDataInformation:        false,
            quickSearch:              '',
            categoriesData:           [],
            categoriesDataOrigin:     [],
            modalAddCategoryVisible:  false,
            selectedCategoryId:       null,
            modalEditCategoryVisible: false,
        };
    },

    watch: {
        quickSearch() {
            this.filter();
        },
    },

    beforeCreate() {
        this.$wait.start('loading.initial_categories');
    },

    created() {
        this.$store.commit('setPageTitle', this.$t('services.categories'));
        this.$store.commit('setActiveMenuItem', '5-2');
        this.getAll();
    },

    methods: {
        async getAll() {
            try {
                this.$wait.start('loading.categories');
                const data = await Api.getAll();
                this.categoriesData = Object.freeze(data);
                this.categoriesDataOrigin = Object.freeze(data);
                this.noDataInformation = data.length;
            } finally {
                this.$wait.end('loading.categories');
                this.$wait.end('loading.initial_categories');
            }
        },

        addCategory() {
            this.modalAddCategoryVisible = true;
        },

        handleEdit(id) {
            this.selectedCategoryId = id;
            this.modalEditCategoryVisible = true;
        },

        handleDelete(id) {
            this.$store.commit('showConfirmationModal', {
                actionUrl:  Api.destroyURL(id),
                actionType: 'destroy',
            });
        },

        filter() {
            this.categoriesData = this.categoriesDataOrigin.filter(category => category.name.toLowerCase().includes(this.quickSearch.toLowerCase()));
        },
    },
};
</script>
